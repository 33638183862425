import { Text } from '@village/ui';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    padding: 0 1rem 1rem;
    margin: 1.25rem 0;
    border-bottom: 1px solid ${({ theme }) => theme.vui.colors.border.divider.default};
`;

const Content = styled.span`
    flex: 1;
    margin-left: 0.75rem;
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

const Title = styled(Text).attrs(() => ({ type: 'h6' }))`
    font-weight: 700 !important;
    margin-bottom: 0.25rem;
`;

const Description = styled(Text).attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.text.secondary};
`;

export { Container, Content, Title, Description };
