import { Text } from '@village/ui';
import styled from 'styled-components';

const Title = styled(Text).attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.text.default};
    font-weight: 400;
`;

const Content = styled(Text).attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

export { Title, Content };
