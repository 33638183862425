import { appConfigSchema } from 'schemas/app-config';
import type { AppConfig } from 'types';

// Environment variables can be set to 'input' or 'undefined' to indicate: None, nothing!
const parse = (input: string | undefined): string => (input === undefined || ['null', 'undefined'].includes(input) ? '' : input);
const asString = (input: string | undefined): string => parse(input).trim();
const asBoolean = (input: string | undefined): boolean => asString(input) === 'true';

const getAppConfig = (): AppConfig => {
    const config: AppConfig = {
        version: asString(import.meta.env.VITE_APP_VERSION),
        aceApiBaseUrl: asString(import.meta.env.VITE_ACE_BASE_URL),
        b2cGraphqlApiUrl: asString(import.meta.env.VITE_B2C_GRAPHQL_API_URL),
        hubspotApiBaseUrl: asString(import.meta.env.VITE_HUBSPOT_API_BASE_URL),
        hubspotProvidersTableId: asString(import.meta.env.VITE_HUBSPOT_PROVIDERS_TABLE_ID),
        hubspotLocationsTableId: asString(import.meta.env.VITE_HUBSPOT_LOCATIONS_TABLE_ID),
        hubspotPortalId: asString(import.meta.env.VITE_HUBSPOT_PORTAL_ID),
        countlyAppKey: asString(import.meta.env.VITE_COUNTLY_APP_KEY),
        countlyUrl: asString(import.meta.env.VITE_COUNTLY_URL),
        environment: (import.meta.env.VITE_ENV ?? 'test') as AppConfig['environment'],
        widgetRedirectToUrl: asString(import.meta.env.VITE_WIDGET_REDIRECT_TO_URL),
        gmb: {
            conversionApiUrl: asString(import.meta.env.VITE_GMB_CONVERSION_API_URL),
            partnerId: 20001722,
        },
        datadog: {
            rumApplicationId: asString(import.meta.env.VITE_DD_RUM_APP_ID),
            rumClientToken: asString(import.meta.env.VITE_DD_RUM_CLIENT_TOKEN),
            logsClientToken: asString(import.meta.env.VITE_DD_LOGS_CLIENT_TOKEN),
            enableSessionRecording: asBoolean(import.meta.env.VITE_DD_ENABLE_SESSION_RECORDING),
            service: 'public-scheduler',
        },
        citymd: {
            datadog: {
                rumApplicationId: asString(import.meta.env.VITE_CITYMD_DD_RUM_APP_ID),
                rumClientToken: asString(import.meta.env.VITE_CITYMD_DD_RUM_CLIENT_TOKEN),
                logsClientToken: asString(import.meta.env.VITE_CITYMD_DD_LOGS_CLIENT_TOKEN),
                enableSessionRecording: asBoolean(import.meta.env.VITE_CITYMD_DD_ENABLE_SESSION_RECORDING),
                service: 'citymd-scheduler',
            },
        },
        citymdCheckin: {
            datadog: {
                rumApplicationId: asString(import.meta.env.VITE_CITYMD_CHECKIN_DD_RUM_APP_ID),
                rumClientToken: asString(import.meta.env.VITE_CITYMD_CHECKIN_DD_RUM_CLIENT_TOKEN),
                logsClientToken: asString(import.meta.env.VITE_CITYMD_CHECKIN_DD_LOGS_CLIENT_TOKEN),
                enableSessionRecording: asBoolean(import.meta.env.VITE_CITYMD_CHECKIN_DD_ENABLE_SESSION_RECORDING),
                service: 'citymd-checkin',
            },
        },
        authCitymd: {
            datadog: {
                rumApplicationId: asString(import.meta.env.VITE_AUTH_CITYMD_DD_RUM_APP_ID),
                rumClientToken: asString(import.meta.env.VITE_AUTH_CITYMD_DD_RUM_CLIENT_TOKEN),
                logsClientToken: asString(import.meta.env.VITE_AUTH_CITYMD_DD_LOGS_CLIENT_TOKEN),
                enableSessionRecording: asBoolean(import.meta.env.VITE_AUTH_CITYMD_DD_ENABLE_SESSION_RECORDING),
                service: 'auth-citymd-scheduler',
            },
        },
        b2cGraphqlApi: {
            url: asString(import.meta.env.VITE_B2C_GRAPHQL_API_URL),
            apiKey: asString(import.meta.env.VITE_B2C_GRAPHQL_API_KEY),
        },
    };

    appConfigSchema.validateSync(config);
    return config;
};

const appConfig = getAppConfig();

export { appConfig, getAppConfig };
