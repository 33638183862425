import styled, { css } from 'styled-components';
import { Modal as ModalBase } from '@village/ui';

export const Modal = styled(ModalBase)`
    z-index: 999;
`;

export const Wrapper = styled.div`
    width: 50rem;
    height: 35rem;
`;

export const Header = styled.div`
    padding: 1rem 1.5rem;
`;

export const HeaderContent = styled.div`
    margin-top: 0.5rem;
    color: ${({ theme }) => theme.vui.colors.text.secondary};
`;

export const StickyHeader = styled.div`
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;

    #date-picker-container {
        margin: 0;
    }
`;

export const SpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(256, 256, 256, 0.7);
    pointer-events: none;
    z-index: 1;
`;

export const SpinnerWrapper = styled.div`
    position: sticky;
    top: 10rem;
`;

export const Content = styled.div<{ $loading: boolean }>`
    position: relative;
    padding: 0 1.5rem 1.5rem;

    ${({ $loading }) =>
        $loading &&
        css`
            background-color: rgba(256, 256, 256, 0.7);
            pointer-events: none;
        `};
`;
