import { useMemo } from 'react';

import type { Booking } from 'types';
import { useHubspotProviders } from './use-hubspot-providers';
import { useAceProviders } from './use-ace-providers';
import { providerFactory } from 'utils/provider';

/**
 * @returns providers: Ace providers enhanced with HubDB providers details
 */
const useProviders = (booking: Booking) => {
    const { data: aceProviders, isFetching: aceProviderFetching, isFetched: aceProvidersFetched } = useAceProviders(booking);
    const {
        data: hubspotProviders,
        isFetching: hubspotProvidersFetching,
        isFetched: hubspotProvidersFetched,
    } = useHubspotProviders(booking, aceProviders ?? []);

    // Enhance Ace providers with the hubDB details.
    const enhancedProviders = useMemo(() => {
        // To avoid too many renders, wait for hubspot providers
        // to load before returning the enhanced providers
        if (!hubspotProviders || !aceProviders) {
            return undefined;
        }

        return aceProviders?.map((aceProvider) => {
            const hubspotProvider = hubspotProviders?.find(({ npi }) => Number(aceProvider.npi) === npi);
            return providerFactory(aceProvider, hubspotProvider);
        });
    }, [aceProviders, hubspotProviders]);

    return {
        data: enhancedProviders ?? [],
        isFetching: aceProviderFetching || hubspotProvidersFetching,
        // If ace response was empty we do not trigger hubDB request to load providers
        isFetched: aceProvidersFetched && (hubspotProvidersFetched || !hubspotProvidersFetching),
    };
};

export { useProviders };
