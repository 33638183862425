import styled from 'styled-components';

import { Image } from 'components/image';

export const AvatarContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const AvatarWrapper = styled.div<{ index: number }>`
    margin-left: ${(props) => (props.index === 0 ? '0' : `-9px`)};
    z-index: ${(props) => 3 - props.index};
    width: 2rem;
    height: 2rem;
`;

export const AvatarImage = styled(Image)`
    border-radius: 50%;
    border: 1px solid white;
    object-fit: cover;
    width: 2rem;
    height: 2rem;
`;
