import { Provider } from 'types';

const getAltText = (provider?: Provider): string => {
    if (!provider) {
        return '';
    }

    return provider.thumbnailUrl.includes('default-profile')
        ? 'Default doctor image'
        : `Professional headshot of ${provider.displayname}`;
};

export { getAltText };
