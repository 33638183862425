import { useToggle } from '@village/tools';
import { Link } from '@village/ui';
import { FC, Fragment, useMemo, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { WIDGET_SLOTS_PER_COLUMN } from 'constants/appointment';
import { Appointment, AppointmentsByDepartmentProvider } from 'types';
import { useAppointmentsRange, useBooking } from 'hooks';
import { NextAvailableAppointments } from 'components/next-available-appointments';
import { buildAppointmentsByDateCollection } from 'widget/utils/date-collection';
import { AppointmentsColumn } from './appointments-column';
import { PrnBadge } from 'components/prn-badge';
import * as Styled from './styles';
import { SchedulingNotAvailable } from 'components/scheduling-not-available';
import { getAltText } from 'utils/image';

export interface Props {
    appointmentsGroup: AppointmentsByDepartmentProvider;
    isAppointmentsFetching: boolean;
    onSelectAppointment: (appointment: Appointment) => void;
}

const AppointmentsCard: FC<Props> = ({ appointmentsGroup, isAppointmentsFetching, onSelectAppointment }) => {
    const { booking } = useBooking();
    const { date: bookingDate } = booking;
    const { datesRange } = useAppointmentsRange();
    const cardRef = useRef<HTMLDivElement | null>(null);
    const { provider, appointments } = appointmentsGroup;
    const { switchOff: showLess, switchOn: showMore, value: showAllAvailability } = useToggle(false);

    // If the provider has a hubspot path, and the provider is not the main provider, display the provider link.
    const displayProviderLink = Boolean(
        !provider.isprn && provider.hubspot?.path && appointmentsGroup.provider !== booking.provider
    );

    // To avoid queries waterfall when user quickly scrolls thought the cards.
    // Delay of 1 second before triggering the next available appointments query for the inView card.
    const { ref, inView } = useInView({ threshold: 0.3, delay: 1000, triggerOnce: true });

    const appointmentsByDate = useMemo(
        () => buildAppointmentsByDateCollection(bookingDate, datesRange, appointments),
        [bookingDate, datesRange, appointments]
    );

    // The appointments for each date have already been padded with empty slots to fill the column.
    // Therefore, we can use the first date to get the number of appointments per column.
    const appointmentCountPerColumn = appointmentsByDate[0].appointments.length;
    const slotsCount = showAllAvailability ? appointmentCountPerColumn : WIDGET_SLOTS_PER_COLUMN;

    const handleShowLessAvailability = () => {
        showLess();
        cardRef.current?.scrollIntoView({ block: 'center' });
    };

    const isProviderEnabledForScheduling = provider.hubspot?.enabledForScheduling === true;

    return (
        <Styled.Container ref={ref} data-provider-id={provider.providerid}>
            <div>
                <Styled.ProviderContainer
                    $hoverEffect={displayProviderLink}
                    as={displayProviderLink ? 'a' : 'div'}
                    href={displayProviderLink ? provider.hubspot?.path : undefined}
                >
                    <Styled.ProviderImage src={provider.thumbnailUrl} alt={getAltText(provider)} />
                    <Styled.ProviderDetails ref={cardRef}>
                        <Styled.ProviderName>{provider.displayname}</Styled.ProviderName>
                        <Styled.ProviderSpecialty>{provider.specialty}</Styled.ProviderSpecialty>
                        {provider.isprn ? (
                            <Styled.PrnNotice>
                                <PrnBadge />
                            </Styled.PrnNotice>
                        ) : null}
                    </Styled.ProviderDetails>
                </Styled.ProviderContainer>
                {!isProviderEnabledForScheduling ? (
                    <SchedulingNotAvailable reason={'NO_PROVIDER'} disabledFor="provider" displayIcon={false} />
                ) : !isAppointmentsFetching ? (
                    <Fragment>
                        <Styled.AvailabilityContainer>
                            <Styled.AppointmentsContainer>
                                {appointmentsByDate.map(({ date, appointments: dateAppointments }) => (
                                    <AppointmentsColumn
                                        key={date}
                                        date={date}
                                        appointments={dateAppointments}
                                        onSelectAppointment={onSelectAppointment}
                                        slotsCount={slotsCount}
                                    />
                                ))}
                            </Styled.AppointmentsContainer>

                            {appointmentCountPerColumn > WIDGET_SLOTS_PER_COLUMN ? (
                                <Styled.AvailabilityControllerWrapper>
                                    {!showAllAvailability ? (
                                        <Link onClick={showMore}>
                                            View all availability <Styled.ViewAllAvailabilityIcon />
                                        </Link>
                                    ) : (
                                        <Link onClick={handleShowLessAvailability}>
                                            View less availability <Styled.ViewLessAvailabilityIcon />
                                        </Link>
                                    )}
                                </Styled.AvailabilityControllerWrapper>
                            ) : null}
                        </Styled.AvailabilityContainer>
                        {appointments.length === 0 && provider.entitytype === 'Person' ? (
                            <Styled.NoAppointmentsContainer data-testid="no-appointments">
                                <NextAvailableAppointments grouping={appointmentsGroup} isInView={inView} />
                            </Styled.NoAppointmentsContainer>
                        ) : null}
                    </Fragment>
                ) : null}
            </div>
        </Styled.Container>
    );
};

export { AppointmentsCard };
