import React from 'react';

import * as Styled from './styles';
import { AvatarMetaData } from 'types';

interface OverlappingAvatarsProps {
    avatars: AvatarMetaData[];
}

export const OverlappingAvatars: React.FC<OverlappingAvatarsProps> = ({ avatars }) => {
    return (
        <Styled.AvatarContainer>
            {avatars.map(({ imageUrl, altText }, index) => (
                <Styled.AvatarWrapper key={index} index={index}>
                    <Styled.AvatarImage src={imageUrl} alt={altText} />
                </Styled.AvatarWrapper>
            ))}
        </Styled.AvatarContainer>
    );
};
