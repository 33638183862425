import { useMemo } from 'react';
import { sort } from 'ramda';

import { useAppointments, useBooking } from 'hooks';
import { AppointmentsByDepartmentProvider, Provider } from 'types';
import { sortAppointmentsByFirstAvailable } from 'utils/appointment';
import { getAllowedAppointmentTypeIds, getAllowedProviders } from 'utils/provider';

/**
 * This hook loads and groups the open appointments per Provider, Department and VisitType.
 */
const useAppointmentsGroups = (providers: Provider[], options?: { featuredProviderId?: number }) => {
    const { booking } = useBooking();
    const {
        data: appointments,
        isFetching,
        refetch: refetchAppointments,
        isFetchedAfterMount: isAppointmentsFetchedAfterMount,
    } = useAppointments(booking, providers);

    const { department, departmentAndRelatedDepartments, reason, market } = booking;

    const appointmentsGroupings = useMemo(() => {
        if (!appointments || !reason || !market || !department) {
            return [];
        }

        const allowedAppointmentTypeIds = getAllowedAppointmentTypeIds(reason, market, department.departmentid);
        const filteredAppointments = appointments.filter(({ appointmenttypeid }) =>
            allowedAppointmentTypeIds.includes(appointmenttypeid)
        );

        const allowedProvidersByDepartment = getAllowedProviders(departmentAndRelatedDepartments, reason, providers);

        const appointmentsGrouped = department
            ? allowedProvidersByDepartment.flatMap<AppointmentsByDepartmentProvider>(
                  ([providerDepartment, departmentProviders]) =>
                      departmentProviders.map((provider) => ({
                          department: providerDepartment,
                          provider,
                          appointments: filteredAppointments.filter(
                              (appointment) =>
                                  appointment.withinAllowedTimeRange &&
                                  appointment.providerid === provider.providerid &&
                                  providerDepartment.departmentid === appointment.departmentid
                          ),
                      }))
              )
            : [];

        const appointmentsSorted = sort(
            (a: AppointmentsByDepartmentProvider, b: AppointmentsByDepartmentProvider) =>
                sortAppointmentsByFirstAvailable(a.appointments[0], b.appointments[0]),
            sort((a, b) => a.provider.displayname.localeCompare(b.provider.displayname), appointmentsGrouped)
        );

        const primaryProviderGroups = appointmentsSorted.filter(
            (group) =>
                group.provider.providerid === booking.provider?.providerid ||
                group.provider.providerid === options?.featuredProviderId
        );

        const primaryProviderGroupsWithPlaceholder =
            primaryProviderGroups.length > 0
                ? primaryProviderGroups
                : booking.provider
                ? [{ department, provider: booking.provider, appointments: [] }]
                : [];

        const disabledForSchedulingGroup = appointmentsSorted.filter(
            (group) => group.provider.hubspot?.enabledForScheduling === false
        );

        const uncategorized = appointmentsSorted.filter(
            (group) => ![...primaryProviderGroups, ...disabledForSchedulingGroup].includes(group)
        );

        return [
            // primary provider should always be at the top
            ...primaryProviderGroupsWithPlaceholder,
            ...uncategorized,
            // providers disabled for scheduling in hubspot should always show last
            ...disabledForSchedulingGroup,
        ];
    }, [
        appointments,
        reason,
        market,
        department,
        departmentAndRelatedDepartments,
        providers,
        booking.provider,
        options?.featuredProviderId,
    ]);

    return {
        refetchAppointments,
        appointmentsGroupings,
        isAppointmentsFetching: isFetching,
        isAppointmentsFetchedAfterMount,
    };
};

export { useAppointmentsGroups };
