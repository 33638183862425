import { GroupIcon } from '@village/icons';
import React from 'react';

import { Department } from 'types';
import * as Styled from './styles';

interface Props {
    department: Department;
}

const BeSeenSoonerHeader: React.FC<Props> = ({ department }) => (
    <Styled.Container data-testid="be-seen-sooner-header">
        <GroupIcon size="md" />
        <Styled.Content>
            <Styled.Title>Need to be seen sooner?</Styled.Title>
            <Styled.Description>
                View other providers at {department.hubspot?.friendlyName || department.name} who might be available to see you.
            </Styled.Description>
        </Styled.Content>
    </Styled.Container>
);

export { BeSeenSoonerHeader };
