import { Text } from '@village/ui';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2rem;

    > * {
        text-align: center;
    }
`;

const Title = styled(Text).attrs(() => ({ type: 'sub4', fontWeight: 400 }))<{ $displayIcon?: boolean }>`
    ${({ $displayIcon }) => $displayIcon === false && 'margin-top: 1rem'}
    margin-bottom: 0.25rem;
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

const SubTitle = styled(Text).attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
`;

const ButtonWrapper = styled.div<{ appType?: string }>`
    width: 100%;
    margin: ${({ appType }) => (appType === 'main' ? '.5rem' : '1rem')};
`;

const Icon = styled.div`
    margin: 1rem 0;
    & svg {
        width: 4.5rem;
        height: 4.5rem;
    }
`;

const Link = styled.a`
    font-family: inherit;
    width: 100%;
    height: 100%;
    position: absolute;
`;

export { ButtonWrapper, Container, Icon, Link, Title, SubTitle };
