import { FC } from 'react';
import { Link, Text } from '@village/ui';
import { ChevronRightIcon } from '@village/icons';

import { Appointment, AppointmentsByDepartmentProvider } from 'types';
import { AppointmentsCard } from '../appointments-card';
import { DatesNavigation } from '../dates-navigation';
import { CenteredSpinner } from 'components/centered-spinner';
import { useAppOptions, useBooking, useCaptureCountlyEvent } from 'hooks';
import * as Styled from './styles';

interface Props {
    open: boolean;
    onCancel: () => void;
    appointmentsGroupings: AppointmentsByDepartmentProvider[];
    onSelectAppointment: (appointment: Appointment) => void;
    isLoading: boolean;
}

export const AvailabilityModal: FC<Props> = ({ onSelectAppointment, appointmentsGroupings, open, isLoading, onCancel }) => {
    const { booking } = useBooking();
    const { department, reason } = booking;
    const { addCountlyEvent } = useCaptureCountlyEvent();
    const { departmentsList, rootElementId } = useAppOptions<'providerWidget'>();
    const location = departmentsList.find((dep) => dep.id && Number(dep.id) === department?.departmentid);

    const handleModalClose = () => {
        addCountlyEvent({ key: 'lightboxClose' });
        onCancel();
    };

    return (
        <Styled.Modal
            open={open}
            onClose={handleModalClose}
            data-testid="availability-modal"
            portalContainer={document.getElementById(rootElementId) || undefined}
        >
            <Styled.Header>
                <Text type="sub2">Providers with more availability</Text>
                <Styled.HeaderContent>
                    <Text type="body1">
                        <Text type="body1" tag="span" fontWeight={700}>
                            Location:
                        </Text>{' '}
                        {department?.hubspot?.friendlyName || department?.name}{' '}
                        {location ? (
                            <Link
                                href={`/locations${location.path}`}
                                onClick={() => addCountlyEvent({ key: 'lightboxClickViewFullScheduler' })}
                            >
                                View full schedule <ChevronRightIcon size="xs" />
                            </Link>
                        ) : null}
                    </Text>{' '}
                    <Text type="body1">
                        <Text type="body1" tag="span" fontWeight={700}>
                            Appointment reason:
                        </Text>{' '}
                        {reason?.name}
                    </Text>
                </Styled.HeaderContent>
            </Styled.Header>
            <Styled.StickyHeader>
                <DatesNavigation isAppointmentsFetching={isLoading} />
            </Styled.StickyHeader>
            <Styled.Wrapper>
                <Styled.Content $loading={isLoading}>
                    {isLoading ? (
                        <Styled.SpinnerContainer>
                            <Styled.SpinnerWrapper>
                                <CenteredSpinner />
                            </Styled.SpinnerWrapper>
                        </Styled.SpinnerContainer>
                    ) : null}
                    {appointmentsGroupings.map((group) => (
                        <AppointmentsCard
                            key={`${group.provider.providerid}-${group.department.departmentid}`}
                            appointmentsGroup={group}
                            isAppointmentsFetching={isLoading}
                            onSelectAppointment={onSelectAppointment}
                        />
                    ))}
                </Styled.Content>
            </Styled.Wrapper>
        </Styled.Modal>
    );
};
