import { useMemo } from 'react';
import { prop } from 'ramda';
import { addDays } from 'date-fns';

import { useAppointmentsRange, useBooking, useNextAvailableAppointments, useProviders, useRemoteConfig } from 'hooks';
import { AppointmentsByDepartmentProvider } from 'types';
import { getFirstNextAppointment } from 'utils/appointment';
import { formatDate } from 'utils/date';

/**
 * This hook loads and groups the open appointments per Provider, Department and VisitType.
 */
export const usePrnNextAvailableAppointment = (appointmentsGroupings: AppointmentsByDepartmentProvider[]) => {
    const { booking } = useBooking();
    const { department, reason, market } = booking;
    const { datesRange } = useAppointmentsRange();
    const { prnVisibleDateRange } = useRemoteConfig();
    const { data: allProviders } = useProviders(booking);

    /**
     * If a PRN provider has no appointments, we fetch their next available appointment
     * to determine if we should display them in the list of available providers.
     */
    const prnProvider = useMemo(() => {
        return allProviders.filter((provider) => {
            return (
                provider.isprn &&
                appointmentsGroupings.some((group) => {
                    return group.provider.providerid === provider.providerid && group.appointments.length === 0;
                })
            );
        });
    }, [appointmentsGroupings, allProviders]);

    /**
     * Fetch availability for PRN providers for current department within x days
     * Department, Market, Reason, etc are passed via the booking object
     *
     * Start date: We start from where the current direct availability query ends for optimization purposes
     * Example:
     * Direct availability query ends at 5 days from today
     * Next available appointments query should start at 6 days from today
     */
    const { data: prnNextAvailableAppointments, isFetching: isNextAvailableFetching } = useNextAvailableAppointments(
        booking,
        {
            providerIds: prnProvider.map(prop('providerid')),
            startDate: formatDate(addDays(booking.date, datesRange + 1), 'monthDayYear'),
            range: prnVisibleDateRange - datesRange,
            bypassScheduleTimeChecks: market?.bypass_schedule_time_checks ?? false,
            departmentIds: booking.departmentAndRelatedDepartments.map(prop('departmentid')),
            marketKey: market?.market_key,
            reasonId: reason?.id,
        },
        {
            // Only fetch next available appointments when direct availability query is completed
            enabled: appointmentsGroupings.length > 0,
        }
    );

    const nextAvailablePrnProviders = useMemo(() => {
        return prnProvider.filter((provider) =>
            getFirstNextAppointment(prnNextAvailableAppointments, department, market, provider.providerid, reason)
        );
    }, [prnProvider, prnNextAvailableAppointments, department, market, reason]);

    /**
     * 1. Filter out appointments for PRN providers that do not have availability in the range provided
     *    To keep them from showing up in the appointments list
     * 2. Filter out providers that do not have availability in the range provided
     *    To keep them from showing up in the provider selection dropdown
     */
    const availabilityWithoutUnavailablePrnProviders = useMemo(() => {
        return appointmentsGroupings.filter((group) => {
            return !group.provider.isprn || group.appointments.length > 0 || nextAvailablePrnProviders.includes(group.provider);
        });
    }, [appointmentsGroupings, nextAvailablePrnProviders]);

    const providersWithoutUnavailablePrnProviders = useMemo(() => {
        return allProviders.filter((provider) => {
            const providerAvailability =
                appointmentsGroupings.find((group) => group.provider.providerid === provider.providerid)?.appointments ?? [];

            return !provider.isprn || providerAvailability.length > 0 || nextAvailablePrnProviders.includes(provider);
        });
    }, [allProviders, appointmentsGroupings, nextAvailablePrnProviders]);

    return {
        isNextAvailableFetching,
        availabilityWithoutUnavailablePrnProviders,
        providersWithoutUnavailablePrnProviders,
    };
};
