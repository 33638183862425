import { BuildingIcon } from '@village/icons';
import { Divider, Text } from '@village/ui';
import { useToggle } from '@village/tools';
import { prop } from 'ramda';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { differenceInCalendarDays } from 'date-fns';

import { DepartmentField, HubspotDepartmentField } from 'components/fields';
import {
    useAppointmentsGroups,
    useBooking,
    useDepartments,
    useMarkets,
    useProviders,
    useReasons,
    useAppOptions,
    useCaptureCountlyEvent,
    useSchedulingNotAvailable,
    useIsMobile,
    useRemoteConfig,
} from 'hooks';
import { Appointment, Booking, Department, DepartmentHubspotInfo } from 'types';
import { Banner } from 'components/banner';
import { DatesNavigation, AppointmentsCard } from 'widget/components';
import { SchedulingNotAvailable } from 'components/scheduling-not-available';
import { CenteredSpinner } from 'components/centered-spinner';
import { ReasonField } from 'widget/components/fields';
import { IsExistingPatientField } from 'components/fields/is-existing-patient';
import { getDefaultReason } from 'widget/utils/reason';
import { filterReasonsByProviders } from 'utils/reason';
import { BeSeenSoonerBanner } from 'widget/components/be-seen-sooner-banner';
import { BeSeenSoonerHeader } from 'widget/components/be-seen-sooner-header';
import { AvailabilityModal } from 'widget/components/availability-modal';
import { usePrnNextAvailableAppointment } from 'hooks/use-prn-next-available-appointment';
import * as Styled from '../styles';

interface Props {
    onSelectAppointment: (appointment: Appointment, booking: Booking, isAlternativeProvider: boolean) => void;
}

const ProviderWidget: FC<Props> = ({ onSelectAppointment }) => {
    const isMobile = useIsMobile();
    const { setBookingField, booking } = useBooking();
    const { addCountlyEvent } = useCaptureCountlyEvent();
    const widgetOptions = useAppOptions('providerWidget');
    const { data: markets, isFetching: isMarketsFetching } = useMarkets();
    const { displayAllAvailableAlternativeProvidersOnLoad } = useRemoteConfig();
    const { data: allReasons, isFetching: isReasonsFetching } = useReasons(booking);
    const { data: departments, isFetching: isDepartmentsFetching } = useDepartments(booking);
    const { schedulingNotAvailableReason, setSchedulingUnavailableReason } = useSchedulingNotAvailable();
    const [providerDepartments, setProviderDepartments] = useState<Department[] | DepartmentHubspotInfo[]>([]);
    const { data: providers, isFetching: isProvidersFetching, isFetched: isProvidersFetched } = useProviders(booking);
    const { isAppointmentsFetching, appointmentsGroupings } = useAppointmentsGroups(providers);
    const {
        value: isAvailabilityModalOpen,
        switchOff: closeAvailabilityModal,
        switchOn: openAvailabilityModal,
    } = useToggle(false);

    const { isNextAvailableFetching, availabilityWithoutUnavailablePrnProviders } =
        usePrnNextAvailableAppointment(appointmentsGroupings);

    /**
     * Only used for providers that are disabled for scheduling as they will not go through a booking flow
     */
    const [disabledForSchedulingLocation, setDisabledForSchedulingLocation] = useState<DepartmentHubspotInfo | null>(null);

    /**
     * Allow only reasons that map to the primary provider and alternative providers
     */
    const reasons = useMemo(() => {
        if (!booking.department || !booking.provider || !allReasons) {
            return [];
        }

        return filterReasonsByProviders(allReasons, providers, booking.departmentAndRelatedDepartments);
    }, [booking.department, booking.provider, booking.departmentAndRelatedDepartments, allReasons, providers]);

    useEffect(() => {
        if (!booking.provider || !allReasons) {
            return;
        }

        const mainProviderReasons = filterReasonsByProviders(
            allReasons,
            [booking.provider],
            booking.departmentAndRelatedDepartments
        );

        setSchedulingUnavailableReason(
            mainProviderReasons.length === 0 && booking.isExistingPatient === false ? 'NO_REASONS_NEW_PATIENTS' : null
        );
    }, [
        booking.provider,
        booking.departmentAndRelatedDepartments,
        booking.isExistingPatient,
        allReasons,
        setSchedulingUnavailableReason,
    ]);

    const location = useMemo(() => {
        if (widgetOptions.enabledForScheduling) {
            return widgetOptions.departmentsList.find(({ id }) => Number(id) === booking.department?.departmentid);
        } else {
            if (!disabledForSchedulingLocation) {
                setDisabledForSchedulingLocation(widgetOptions.departmentsList[0]);
            }
            return disabledForSchedulingLocation;
        }
    }, [
        booking.department?.departmentid,
        disabledForSchedulingLocation,
        widgetOptions.departmentsList,
        widgetOptions.enabledForScheduling,
    ]);

    useEffect(() => {
        if (markets && !booking.market) {
            const market = markets.find(({ market_key }) => market_key === widgetOptions.marketKey);
            setBookingField({ market });
            setSchedulingUnavailableReason(!market ? 'NO_MARKET' : null);
        }
    }, [setBookingField, markets, widgetOptions.marketKey, booking.market, setSchedulingUnavailableReason]);

    useEffect(() => {
        if (widgetOptions.enabledForScheduling === false) {
            setProviderDepartments(widgetOptions.departmentsList.filter((department) => department.id !== null));
        } else {
            if (departments && !booking.department) {
                const departmentsListIds = widgetOptions.departmentsList.map(prop('id')).map(Number);
                const schedulableDepartments = booking.market?.schedulable_department_ids ?? [];
                const departmentsLookup = departments.filter(
                    ({ departmentid }) =>
                        departmentsListIds.includes(departmentid) && schedulableDepartments.includes(departmentid)
                );

                const defaultDepartment = departmentsLookup.find(
                    ({ departmentid }) => departmentid === widgetOptions.defaultDepartmentId
                );

                const department = defaultDepartment ?? departmentsLookup[0];

                setProviderDepartments(departmentsLookup);
                setBookingField({ department });
                setSchedulingUnavailableReason(!department ? 'NO_DEPARTMENT' : null);
            }
        }
    }, [setBookingField, departments, widgetOptions, booking.department, booking.market, setSchedulingUnavailableReason]);

    useEffect(() => {
        if (reasons.length > 0 && !booking.reason) {
            const defaultReason = getDefaultReason(reasons, booking.isExistingPatient);
            const initialReason = reasons.find(({ id }) => id === widgetOptions.reasonId);
            setBookingField({ reason: initialReason ?? defaultReason });
        }
    }, [setBookingField, reasons, booking.isExistingPatient, booking.reason, widgetOptions.reasonId]);

    useEffect(() => {
        if (isProvidersFetched && !booking.provider) {
            const provider = providers.find(({ npi }) => npi === widgetOptions.providerNpi);
            setBookingField({ provider });
            setSchedulingUnavailableReason(!provider ? 'NO_PROVIDER' : null);
        }
    }, [
        setBookingField,
        providers,
        widgetOptions.providerNpi,
        booking.provider,
        isProvidersFetched,
        setSchedulingUnavailableReason,
    ]);

    const handleSelectAppointment = (appointment: Appointment) => {
        const provider = providers.find(({ providerid }) => providerid === appointment.providerid);
        const isAlternativeProvider = provider?.providerid !== booking.provider?.providerid;

        // This property will be used to help identify the difference between the first day in
        // the widget scheduler and the day of the appointment that was selected.
        const scheduledDaysOut = differenceInCalendarDays(new Date(appointment.date), booking.date);

        addCountlyEvent({
            key: 'selectSlot',
            segmentation: {
                appointmentId: appointment.appointmentid,
                appointmentTypeId: appointment.appointmenttypeid,
                appointmentSlotOrigin: 'widget-provider',
                providerId: appointment.providerid,
                providerName: provider?.displayname,
                scheduledDaysOut,
                time: appointment.starttime,
                isAlternativeProvider,
            },
        });

        onSelectAppointment(appointment, booking, isAlternativeProvider);
    };

    useEffect(() => {
        if (widgetOptions.enabledForScheduling && schedulingNotAvailableReason === 'DISABLED') {
            setSchedulingUnavailableReason(null);
        } else if (widgetOptions.enabledForScheduling === false) {
            setSchedulingUnavailableReason('DISABLED');
        }
    }, [widgetOptions.enabledForScheduling, setSchedulingUnavailableReason]);

    const isFetching =
        isAppointmentsFetching || isReasonsFetching || isProvidersFetching || isMarketsFetching || isNextAvailableFetching;

    const selectedProviderGroup = availabilityWithoutUnavailablePrnProviders.find(
        ({ provider }) => provider.providerid === booking.provider?.providerid
    );

    const otherProvidersEnabledForScheduling = availabilityWithoutUnavailablePrnProviders.filter(
        ({ provider }) => provider.hubspot?.enabledForScheduling && provider.providerid !== booking.provider?.providerid
    );

    return (
        <Styled.Container>
            <Styled.Title>Book Appointment</Styled.Title>
            <Styled.EmergencyNotice>
                In case of emergency or life threatening illness, call 911 or go to your local ER
            </Styled.EmergencyNotice>

            <Styled.FieldsWrapper>
                {providerDepartments.length > 1 ? (
                    <Styled.DepartmentFieldWrapper>
                        <Banner status="warning" icon={BuildingIcon}>
                            <Text type="caption1">This provider sees patients at multiple locations.</Text>
                        </Banner>
                        {widgetOptions.enabledForScheduling === false && widgetOptions.departmentsList ? (
                            <HubspotDepartmentField
                                id="select-location"
                                label="Location:"
                                departments={widgetOptions.departmentsList}
                                isSearchable={false}
                                location={location}
                                handleDisabledLocationChange={setDisabledForSchedulingLocation}
                            />
                        ) : (
                            <DepartmentField
                                id="select-department"
                                label="Location:"
                                departments={providerDepartments as Department[]}
                                isLoading={isDepartmentsFetching}
                                isSearchable={false}
                            />
                        )}
                    </Styled.DepartmentFieldWrapper>
                ) : null}
                <IsExistingPatientField />
                <ReasonField reasons={reasons} isReasonsFetching={isReasonsFetching} />
            </Styled.FieldsWrapper>

            <DatesNavigation isAppointmentsFetching={isAppointmentsFetching} />

            {widgetOptions.enabledForScheduling === false && schedulingNotAvailableReason ? (
                <SchedulingNotAvailable
                    reason={schedulingNotAvailableReason}
                    location={location}
                    disabledFor="provider"
                    displayIcon
                />
            ) : (
                <Styled.RelativeContainer>
                    {isFetching ? (
                        <Styled.SpinnerContainer>
                            <Styled.SpinnerWrapper>
                                <CenteredSpinner />
                            </Styled.SpinnerWrapper>
                        </Styled.SpinnerContainer>
                    ) : null}
                    <Styled.AppointmentsContainer $loading={isFetching}>
                        {schedulingNotAvailableReason ? (
                            <SchedulingNotAvailable
                                reason={schedulingNotAvailableReason}
                                location={location}
                                disabledFor="provider"
                                displayIcon
                            />
                        ) : selectedProviderGroup ? (
                            <Fragment>
                                <AppointmentsCard
                                    key={`${selectedProviderGroup.provider.providerid}-${selectedProviderGroup.department.departmentid}`}
                                    appointmentsGroup={selectedProviderGroup}
                                    isAppointmentsFetching={isAppointmentsFetching}
                                    onSelectAppointment={handleSelectAppointment}
                                />
                                {isMobile ? <Divider /> : null}
                            </Fragment>
                        ) : null}

                        {otherProvidersEnabledForScheduling.length > 0 ? (
                            <Fragment>
                                {isMobile || displayAllAvailableAlternativeProvidersOnLoad ? (
                                    <Fragment>
                                        <BeSeenSoonerHeader department={otherProvidersEnabledForScheduling[0].department} />
                                        {otherProvidersEnabledForScheduling.map((group) => (
                                            <AppointmentsCard
                                                key={`${group.provider.providerid}-${group.department.departmentid}`}
                                                appointmentsGroup={group}
                                                isAppointmentsFetching={isAppointmentsFetching}
                                                onSelectAppointment={handleSelectAppointment}
                                            />
                                        ))}
                                    </Fragment>
                                ) : (
                                    <BeSeenSoonerBanner
                                        department={otherProvidersEnabledForScheduling[0].department}
                                        providers={otherProvidersEnabledForScheduling.map(({ provider }) => provider)}
                                        onViewMoreProviders={openAvailabilityModal}
                                    />
                                )}
                            </Fragment>
                        ) : null}
                    </Styled.AppointmentsContainer>
                </Styled.RelativeContainer>
            )}

            <AvailabilityModal
                open={!isMobile && isAvailabilityModalOpen}
                onSelectAppointment={handleSelectAppointment}
                isLoading={isFetching}
                onCancel={closeAvailabilityModal}
                appointmentsGroupings={availabilityWithoutUnavailablePrnProviders.filter(
                    ({ provider }) => provider.hubspot?.enabledForScheduling
                )}
            />
        </Styled.Container>
    );
};

export { ProviderWidget };
