import { defaultVillageUiTheme } from '@village/ui';
import { mergeDeepLeft } from 'ramda';
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = mergeDeepLeft(
    {
        vui: {
            platform: 'web',
            component: {
                input: {
                    default: {
                        default: {
                            textColor: '#2C3542',
                        },
                    },
                },
                autocomplete: {
                    default: {
                        textColor: '#2C3542',
                    },
                },
                tag: {
                    default: {
                        backgroundColor: '#DCEAEF',
                    },
                },
            },
            colors: {
                text: {
                    grayDefault: '#2C3542',
                    grayMuted: '#4C535E',
                    secondary: '#565d68',
                    slate600: '#626a74',
                },
            },
        },
    },
    defaultVillageUiTheme
);

export { theme };
