import { Text } from '@village/ui';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: flex-start;
    background-color: #f4f5f6;
    padding: 1rem;
    margin-top: 1.25rem;
    border-radius: 8px;
`;

const Content = styled.span`
    display: grid;
    gap: 0.5rem;
    margin-left: 1rem;
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

const Title = styled(Text).attrs(() => ({ type: 'h6' }))`
    font-weight: 700;
`;

export { Container, Content, Title };
