import { useCallback, useMemo, FC, useState, useEffect } from 'react';
import { Button, Spinner, Text } from '@village/ui';
import { prop } from 'ramda';
import { differenceInCalendarDays, addDays } from 'date-fns';

import * as Styled from './styles';
import { formatDate } from 'utils/date';
import { AppointmentsByDepartmentProvider } from 'types';
import { useBooking, useRemoteConfig, useNextAvailableAppointments, useCaptureCountlyEvent, useAppointmentsRange } from 'hooks';
import { getFirstNextAppointment } from 'utils/appointment';

interface Props {
    isInView: boolean;
    grouping: AppointmentsByDepartmentProvider;
}

const NextAvailableAppointments: FC<Props> = ({ isInView, grouping }) => {
    const { datesRange } = useAppointmentsRange();
    const { booking, setBookingField } = useBooking();
    const { addCountlyEvent } = useCaptureCountlyEvent();
    const { market, date, department, reason, departmentAndRelatedDepartments } = booking;
    const [isNextAppointmentsSecondAttempt, setNextAppointmentsSecondAttempt] = useState(false);
    const { nextAvailableDateRange, nextAvailableDateRangeSecondary } = useRemoteConfig();

    const { startDate, dateRange, fullDateRange } = useMemo(() => {
        /**
         * We start from where the current direct availability query ends for optimization purposes
         * Example:
         *  Direct availability query ends at 5 days from today
         *  Next available appointments query should start at 6 days from today
         * */
        const startDateBase = addDays(date, datesRange);

        if (!isNextAppointmentsSecondAttempt) {
            return {
                startDate: startDateBase,
                dateRange: nextAvailableDateRange - datesRange,
                fullDateRange: nextAvailableDateRange,
            };
        }

        return {
            startDate: addDays<Date>(startDateBase, nextAvailableDateRange - datesRange),
            dateRange: nextAvailableDateRangeSecondary,
            fullDateRange: nextAvailableDateRange + nextAvailableDateRangeSecondary,
        };
    }, [date, datesRange, isNextAppointmentsSecondAttempt, nextAvailableDateRangeSecondary, nextAvailableDateRange]);

    const {
        data: appointments,
        isFetching,
        isError,
        isFetched,
    } = useNextAvailableAppointments(
        booking,
        {
            providerIds: grouping.provider?.providerid ? [grouping.provider.providerid] : [],
            startDate: formatDate(startDate, 'monthDayYear'),
            range: dateRange,
            bypassScheduleTimeChecks: market?.bypass_schedule_time_checks ?? false,
            departmentIds: departmentAndRelatedDepartments.map(prop('departmentid')),
            marketKey: market?.market_key,
            reasonId: reason?.id,
        },
        { enabled: isInView }
    );

    const firstNextAppointment = useMemo(
        () => getFirstNextAppointment(appointments, department, market, grouping.provider?.providerid, reason),
        [appointments, reason, department, market, grouping.provider?.providerid]
    );

    const handleSetDate = useCallback(
        (inputDate: string) => {
            const daysUntilNextAppointment = differenceInCalendarDays(new Date(inputDate), new Date());

            addCountlyEvent({
                key: 'clickFindAppointmentsNextDate',
                segmentation: {
                    nextAvailableAppointmentInDays: daysUntilNextAppointment,
                    providerId: grouping.provider.providerid,
                    providerName: grouping.provider.displayname,
                },
            });

            setBookingField({ date: new Date(inputDate) });
        },
        [addCountlyEvent, grouping, setBookingField]
    );

    const handleIncrementDateRange = useCallback(() => {
        addCountlyEvent({
            key: 'clickFindAppointmentsNext',
            segmentation: {
                providerId: grouping.provider.providerid,
                providerName: grouping.provider.displayname,
            },
        });

        setNextAppointmentsSecondAttempt(true);
    }, [addCountlyEvent, grouping.provider]);

    useEffect(() => {
        setNextAppointmentsSecondAttempt(false);
    }, [date]);

    if (isFetching) {
        return (
            <Styled.SpinnerContainer>
                <Spinner />
            </Styled.SpinnerContainer>
        );
    }

    if (!isFetched) {
        return null;
    }

    if (isError) {
        return <Text type="body1">Could not load next available appointments for this provider.</Text>;
    }

    if (firstNextAppointment) {
        return (
            <Styled.AppointmentsNextAvailableContainer>
                <Button
                    variant="secondary"
                    size="medium"
                    onClick={() => handleSetDate(firstNextAppointment.date)}
                    data-testid="next-available-appointment"
                    fullWidth={true}
                >
                    Next {reason?.is_in_person === false ? 'video' : 'in-office'} visit:{' '}
                    {formatDate(firstNextAppointment.dateTimeParsed, 'monthDayShort')}
                </Button>
            </Styled.AppointmentsNextAvailableContainer>
        );
    }

    return (
        <Styled.AppointmentsNextAvailableContainer>
            <Styled.NoAvailabilityContainer>
                No availability for this provider in the next {fullDateRange} days
            </Styled.NoAvailabilityContainer>
            {!isNextAppointmentsSecondAttempt ? (
                <Button variant="secondary" size="medium" onClick={handleIncrementDateRange} fullWidth={true}>
                    Find next available
                </Button>
            ) : null}
        </Styled.AppointmentsNextAvailableContainer>
    );
};

export { NextAvailableAppointments };
