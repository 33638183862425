import { ChevronDownIcon, ChevronRightIcon } from '@village/icons';
import { Button, Text } from '@village/ui';
import styled, { css } from 'styled-components';

import { Image } from 'components/image';
import { device } from 'utils/device';

const Container = styled.div`
    padding-top: 1.5rem;

    &:not(&:last-of-type) {
        padding-bottom: 1.5rem;
        border-bottom: 1px solid ${({ theme }) => theme.vui.colors.border.divider.default};
    }
`;

const ProviderName = styled(Text).attrs(() => ({ type: 'sub4' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

const ProviderContainer = styled.div<{ $hoverEffect: boolean }>`
    display: flex;
    margin-bottom: 1.25rem;

    ${({ $hoverEffect }) =>
        $hoverEffect &&
        css`
            @media (hover: hover) {
                &:hover {
                    ${ProviderName} {
                        text-decoration: underline;
                    }
                }
            }
        `}
`;

const ProviderImage = styled(Image)`
    border-radius: 50%;
    border: 1px solid #ddd;
    object-fit: cover;
    height: 3.5rem;
    width: 3.5rem;
    margin-right: 0.75rem;
`;

const ProviderDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ProviderSpecialty = styled(Text).attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.text.grayMuted};
    font-weight: 400;
`;

const PrnNotice = styled.div`
    margin-top: 0.25rem;
`;

const NoAppointmentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    padding: 2rem 0;

    @media ${device.maxTablet} {
        padding: 0.5rem 0;
    }
`;

const AppointmentsContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`;

const AppointmentsColumn = styled.div`
    text-align: center;
    width: 100%;
`;

const AppointmentsColumnDay = styled(Text).attrs(() => ({ type: 'body1' }))<{ $disabled: boolean }>`
    color: ${({ theme, $disabled }) => ($disabled ? theme.vui.colors.neutral.gray40 : theme.vui.colors.text.grayMuted)};
`;

const AppointmentsColumnDate = styled(Text).attrs(() => ({ type: 'body1' }))<{ $disabled: boolean }>`
    color: ${({ theme, $disabled }) => ($disabled ? theme.vui.colors.neutral.gray40 : theme.vui.colors.text.graySubtle)};
    margin-bottom: 1.25rem;
`;

const AppointmentList = styled.div`
    display: grid;
    grid-auto-rows: 1fr;
    align-items: center;
    gap: 0.5rem;
`;

const AvailabilityContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
`;

const AvailabilityControllerWrapper = styled.div`
    margin: 1.5rem auto 0;
`;

const SlotButton = styled(Button)`
    white-space: nowrap;
`;

const SlotPlaceholder = styled.span<{ $height: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ $height }) => $height}px;
    width: 100%;
    color: ${({ theme }) => theme.vui.colors.text.disabled};
`;

const ViewProviderIcon = styled(ChevronRightIcon).attrs(() => ({ size: 'xs' }))`
    margin-left: 0.25rem;
`;

const ViewAllAvailabilityIcon = styled(ChevronDownIcon).attrs(() => ({ size: 'xs' }))`
    margin-left: 0.25rem;
`;

const ViewLessAvailabilityIcon = styled(ViewAllAvailabilityIcon)`
    transform: rotate(180deg);
    transition: transform 1s ease-in-out;
`;

export {
    Container,
    PrnNotice,
    ProviderContainer,
    ProviderImage,
    ProviderDetails,
    ProviderName,
    ProviderSpecialty,
    NoAppointmentsContainer,
    AppointmentsContainer,
    SlotButton,
    ViewProviderIcon,
    ViewAllAvailabilityIcon,
    ViewLessAvailabilityIcon,
    AvailabilityControllerWrapper,
    AvailabilityContainer,
    AppointmentsColumn,
    AppointmentsColumnDate,
    AppointmentsColumnDay,
    AppointmentList,
    SlotPlaceholder,
};
