import { isEmpty, toPairs, values } from 'ramda';
import { keepPreviousData, QueryObserverOptions, useQuery, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { addDays } from 'date-fns';

import { Appointment, Booking, Department, AppointmentQueryParams, Provider } from 'types';
import { appointmentFactory, getAppointmentsQuery, getFrozenAppointmentsIds } from 'utils/appointment';
import { formatDate } from 'utils/date';
import { filterProvidersByAppointmentTypeId } from 'utils/provider';
import { useFreezeAppointment } from './use-freeze-appointment';
import { useAppointmentsRange } from './use-appointments-range';

const getAppointmentsParams = (
    booking: Booking,
    providers: Provider[],
    { datesRange }: { datesRange: number }
): AppointmentQueryParams[] => {
    const { market, date, departmentAndRelatedDepartments, reason } = booking;

    if (departmentAndRelatedDepartments.length === 0 || !reason || providers.length === 0) {
        return [];
    }

    const departmentWithAppointmentTypeIdsPair = departmentAndRelatedDepartments.reduce<[Department, Record<string, number[]>][]>(
        (accumulator, department) => {
            // Get the appointment type ids for the reason and then query for appointments given the appointment type id
            // and the providers associated with that appointment type id
            const providersByAppointmentTypeId = filterProvidersByAppointmentTypeId(reason, providers, department.departmentid);

            if (providersByAppointmentTypeId && !isEmpty(providersByAppointmentTypeId)) {
                return [...accumulator, [department, providersByAppointmentTypeId]];
            }

            return accumulator;
        },
        []
    );

    if (reason.emr_reason_id) {
        return departmentWithAppointmentTypeIdsPair.map(([{ departmentid }, providersByAppointmentTypeId]) => {
            const providersIds = values(providersByAppointmentTypeId).flatMap((item) => item);

            return {
                bypassscheduletimechecks: market?.bypass_schedule_time_checks ?? false,
                departmentid,
                enddate: formatDate(addDays(date, datesRange - 1), 'monthDayYear'),
                ignoreschedulablepermission: true,
                providerid: providersIds.join(),
                showfrozenslots: false,
                startdate: formatDate(date, 'monthDayYear'),
                reasons: reason.emr_reason_id ?? undefined,
            };
        });
    } else {
        return departmentWithAppointmentTypeIdsPair.flatMap(([{ departmentid }, providersByAppointmentTypeId]) =>
            toPairs(providersByAppointmentTypeId).map(([appointmentTypeId, providerIds]) => ({
                bypassscheduletimechecks: market?.bypass_schedule_time_checks ?? false,
                departmentid: departmentid,
                enddate: formatDate(addDays(date, datesRange - 1), 'monthDayYear'),
                ignoreschedulablepermission: true,
                providerid: providerIds.join(),
                showfrozenslots: false,
                startdate: formatDate(date, 'monthDayYear'),
                appointmenttypeid: Number(appointmentTypeId),
            }))
        );
    }
};

const useAppointments = (
    booking: Booking,
    providers: Provider[],
    options?: Partial<QueryObserverOptions<Appointment[]>>
): UseQueryResult<Appointment[]> => {
    const { datesRange } = useAppointmentsRange();
    const { mutateAsync: freezeAppointmentMutation } = useFreezeAppointment(booking);
    const { market, departmentAndRelatedDepartments, reason } = booking;

    const appointmentsParams = useMemo(
        () => getAppointmentsParams(booking, providers, { datesRange }),
        [booking, providers, datesRange]
    );

    return useQuery({
        queryKey: ['open-appointments', appointmentsParams],
        // Do not use Async/Await.
        // Using Async/Await creates a new promise without `cancel`, so the query cannot be cancelled.
        queryFn: ({ signal }) => {
            const frozenAppointmentsIds = getFrozenAppointmentsIds();

            // Unfreeze all currently frozen appointments before fetching open appointments
            // Mostly it's only one appointment that we have to unfreeze
            const unfreezeAppointmentsQueries = Promise.all(
                frozenAppointmentsIds.map((appointmentid) => freezeAppointmentMutation({ appointmentid, freeze: false }))
            );

            return unfreezeAppointmentsQueries.then(() => {
                const queries = appointmentsParams.map((params) => getAppointmentsQuery(params, signal, market));

                return Promise.all(queries).then((allResponses) =>
                    allResponses
                        .flatMap(({ data }) => data.appointments)
                        // For some reason, backend might return undefined/null sometimes
                        // Reference https://app.datadoghq.com/rum/error-tracking/issue/08c27aac-5bd6-11ee-806a-da7ad0900002
                        .filter((appointment) => appointment)
                        .map((appointment) => appointmentFactory(appointment, departmentAndRelatedDepartments, reason))
                );
            });
        },
        ...options,
        staleTime: 0,
        enabled: options?.enabled !== false && appointmentsParams.length > 0,
        placeholderData: keepPreviousData,
    });
};

export { useAppointments };
