import type { FC } from 'react';
import { Spinner } from '@village/ui';

import * as Styled from './styles';

const CenteredSpinner: FC = () => (
    <Styled.Container data-testid="centered-spinner">
        <Styled.Wrapper>
            <Spinner />
        </Styled.Wrapper>
    </Styled.Container>
);

export { CenteredSpinner };
