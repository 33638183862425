import { HubspotObject } from 'types';
import { HubspotLocation } from 'types/location';

export const hubspotLocationFactory = ({ values }: HubspotObject): HubspotLocation => {
    return {
        state: values.state,
        city: values.city,
        address: values.address,
        address2: values.address_2 ?? undefined,
        zipcode: values.zipcode,
        fullAddress: values.full_address,
        departmentId: values.department_id,
        marketKey: values.market_key,
        departmentAddressId: values.department_address_id ?? undefined,
        phone: values.phone_number,
        friendlyName: values.friendly_name,
    };
};
